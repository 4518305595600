import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { FinancialToolsOfferWall } from '../components/FinancialToolsOfferWall/FinancialToolsOfferWall';
import { Hero } from '../components/InnerPages/Hero';
import DebtIcon from '../images/Debt';

import style from '../styles/pages/extra-money.scss';

import { Helmet } from 'react-helmet';

interface IDebtProps {
  location: any;
}

const Debt = ({ location }: IDebtProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
       var _clcktt = _clcktt || {};
        _clcktt['cIdDesktop'] = "68026";
        _clcktt['cIdMobile'] = "204157";
        (function() {
            var ca = document.createElement('script'); ca.type = 'text/javascript';
            ca.async = true;
            ca.src = '//revpie.com/api/js/extractor.js';
            var sc = document.getElementsByTagName('script')[0]; sc.parentNode.insertBefore(ca, sc);
        })();
      `}</script>
        <style>{`
        .container {
          background-color: initial !important;
        }

        main > div {
          background-color: #fff;
        }
      `}</style>
      </Helmet>
      <MetaData data={{}} location={location} title="Debt-free future starts here" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <Hero
          className={style.hero}
          icon={<DebtIcon />}
          title="Debt-free future starts here"
          description="Your path to financial freedom"
        />
        <div className={style.content}>
          <div className="container">
            <div id="clcktt"></div>
            <FinancialToolsOfferWall
              items={[
                {
                  title: 'How I paid off $25k of debt',
                  link: '/pay-off-debt-fast/',
                },
                {
                  title: 'Tackling Debt',
                  link: '/tag/tackling-debt/',
                },
                {
                  title: 'Over 10K Debt?',
                  link: 'https://leads-inst482-client.phonexa.com/lynx/click?offer_id=5&aff_id=5',
                },
              ]}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Debt;
